.CheckboxCol {
    text-align: left;
}

.active {
    background-color: rgb(27, 102, 136) !important;
}

.RedText {
    color: #d9534f;
}