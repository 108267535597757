.OutputArea {
    text-align: left;
}

.InputArea {
    padding: 10px !important;
    font-size: 20px !important;
}

.OutputBox {
    border: 1px solid rgb(226, 226, 226);
    border-radius: 5px;
    padding: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
}

.btn {
    margin:auto !important;
    margin-top: 20px !important;
    width: 60%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.2);
}

.CustomPrimary{
    border: 0px !important;
    background-color: rgb(27, 102, 136) !important;
}

.CustomDanger {
    border: 0px !important;
    background-color: rgb(194, 23, 23) !important;
}

.__json-pretty__{
    font-family: "Oswald";
    font-size: 20px;
    line-height:1.3;
    color:#000000;
    background:#fffefe;
    overflow:auto;
}

.__json-pretty__ .__json-key__{
    color:#ff5e5e
}.__json-pretty__ .__json-value__{
    color:#fdb082
}.__json-pretty__ .__json-string__{
    color:#3e17ca
}.__json-pretty__ .__json-boolean__{
    color:rgb(0, 4, 255)
}.__json-pretty-error__{
    line-height:1.3;color:#ff0000;background:#ffffff;overflow:auto;font-family: "Oswald";font-size: 20px;
}
