.CustomDangerDelete {
  border: 0px !important;
  background-color: rgb(194, 23, 23) !important;
  max-width: 50px;
}

.AdminList{
    text-align:left !important;
}

.CustomDangerDelete{
    display: inline;
    float: right
}
