.App {
    background-color: aquamarine;
}

.App > .ListGroup {
    margin-left: 200px;
    text-align: center;
}

.App > p {
    font-size: 20;
    font-weight: bold;
}