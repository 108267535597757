.HomeContainer {
    overflow: hidden;
    padding-bottom: 100px;
}

.NavCard{
    background-color: rgb(27, 102, 136);
    border-radius: 15px;
    width: 30%;
    margin:auto;
    text-decoration: none;
    color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.2);
}

.CardTitle{
    padding-top:10px
}

.introduction {
    margin-bottom: 10vh;
    font-size: 7vw;
    animation: 4s ease 0s normal forwards 1 fadein;
}

.CustomLink {
    color: rgba(255, 255, 255, 0.596) !important;
    font-size: 3vw !important;
}

.CustomLink:hover { 
    color: rgb(255, 255, 255)!important;
}

.TypewriterContainer {
    display: inline-block;
}

.TypewriterText{
    font-size: 10vw;
    text-shadow: 2px 2px 2px rgb(27, 102, 136);
    border-right: 5px solid;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    animation: 
        typing 2s steps(18),
        cusror .4s step-end infinite alternate;
}

@media (min-width: 1200px) {
    .TypewriterText {
        font-size: 8vw;
    }

}

@media (max-width: 576px) {
    .NavCard{
        width: 50%;
        margin:auto;
        text-decoration: none;
        color: #fff;
    }

    .CustomLink {
        font-size: 6vw !important;
    }
}

@keyframes cusror {
    50%  {border-color: transparent}
}

@keyframes typing {
    from {width:0}
}

.introduction {
    font-size: 7vw;
    animation: 4s ease 0s normal forwards 1 fadein;
}

@keyframes fadein{
    0% { opacity:0; }
    66% { opacity:0; }
    100% { opacity:1; }
}
