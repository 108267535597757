.Certification {
    display: inline-block;
    margin-right:10px;
    margin-bottom: 10px;
    color: #fff;
    border-radius: 15px 15px 15px 15px;
    background-color: rgb(27, 102, 136);
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.2);
    padding: 10px 12px;
}

.ColumnTextLeft {
    text-align: left;
}

.ExperienceHeading {
    border-radius: 15px 15px 15px 15px;
    background-color: rgb(27, 102, 136);
    color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.2);
    padding: 5px 5px 10px 20px;
    margin-bottom: 20px;
}

.InlineTitle {
    display: inline;
    font-size: 25px;
    font-weight:450;
}

.InlineHeading {
    display: inline;
    margin-left: 20px;
    font-size: 20px;
    font-style: italic;
}

.DownloadResumeButton {
    width: 50%;
    color:#fff;
    text-underline: none;
    padding: 5px 5px 10px 10px;
    margin:auto;
    margin-top:30px;
    border-radius: 15px 15px 15px 15px;
    background-color: rgb(27, 102, 136);

}

.ExperienceList {
    margin-bottom: 30px;
}