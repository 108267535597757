.AboutPageContainer {
  text-align: left;
  font-size: 20px;
  padding: 0;
  margin: auto;
}

.Headshot {
  width: 60vw;
  height: 60vw;
  object-fit: cover;
  object-position: 100% 10%;
  background-color: rgb(27, 102, 136);
  padding: 10px;
  border-radius: 15px;

}

.Wrap {
  float: right;
  margin: 20px;
}

@media (max-width: 990px) {
  .AboutPageContainer {
    text-align: center;
  }
  .Wrap {
    float: none;
    margin: 20px;
  }
}

@media (min-width: 990px) {
  .Headshot {
    width: 350px;
    height: 350px;
  }
}
