.CustomNavbar {
    background-image: linear-gradient(to bottom,rgb(27, 102, 136),rgb(27, 102, 136) 100%) !important; /* override background image gradient w/ flat color */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.2);
}

.LogoImage {
    width: 300px;
}

.CustomNavLink {
    text-align: center;
    width: 70px;
    margin-right: 10px;
}

.CustomActive {
    color: #fff !important;
}

.dropdown-menu {
    text-align: center;
    border: none;
    padding: 0px;
    border-radius: 0px 0px 15px 15px;
    background-color: rgb(27, 102, 136);
    margin-top: 24px !important;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.2);
}

.dropdown-item:hover {
    background-color: rgb(21, 76, 102);
    border-radius: 0px 0px 15px 15px;
}

.dropdown .dropdown-menu {
    transition: all 0.5s;
    max-height: 0;
    display: block;
    overflow: hidden;
    opacity: 0;
}

.dropdown.show .dropdown-menu { /* For Bootstrap 4, use .dropdown.show instead of .dropdown.open */
    max-height: 200px;
    opacity: 1;
}

@media (max-width: 990px) {
    .dropdown-menu {
        margin-top: 0px !important;
        text-align: left;
        box-shadow: none;
    }
}

@media (max-width: 25em) {
    .LogoImage {
        width: 225px;
    }
}

@media (max-width: 20em) {
    .LogoImage {
        width: 125px;
    }
}