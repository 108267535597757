.OutputArea {
    text-align: left;
}

.InputArea {
    padding: 10px !important;
    font-size: 20px !important;
}

.OutputBox {
    border: 1px solid rgb(226, 226, 226);
    border-radius: 5px;
    padding: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
}

.btn {
    margin:auto !important;
    margin-top: 20px !important;
    width: 100%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.2);
}

.CustomPrimary{
    border: 0px !important;
    background-color: rgb(27, 102, 136) !important;
}

.CustomDanger {
    border: 0px !important;
    background-color: rgb(194, 23, 23) !important;
}

.ButtonHolder {
    width: 80%;
    margin: auto;
}

