@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&family=Permanent+Marker:wght@200..700&display=swap');


* {
    padding: 0;
    margin: 0;
    font-family: "Oswald", "Helvetica Neue", "sans-serif";
    font-size: 20px;
}

.PageContent {
    text-align: center;
    margin:auto;
    margin-top: 50px;
    margin-bottom: 50px;
    max-width: 70%;
}

@media (max-width: 1200px) {
    .PageContent {
        max-width: 80%;
    }
}

@media (max-width: 1000px) {
    .PageContent {
        max-width: 85%;
    }
}

@media (max-width: 576px) {
    .PageContent {
        max-width: 90%;
    }
}

.slide-in {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 90px);
    background: rgb(27, 102, 136);
    transform-origin: bottom;
    margin-top: 90px;
}

.slide-out {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 90px);
    background: rgb(27, 102, 136);
    transform-origin: top;
    margin-top: 90px;
}


@media (max-width: 990px) {
    .slide-in {
        margin-top: 70px;
        height: calc(100% - 70px);
    }
    
    .slide-out {
        margin-top: 70px;
        height: calc(100% - 70px);
    }
}



