.ContactCard {
    font-size: 1.3rem !important;
    color: white !important;
    background-color: rgb(27, 102, 136);
    padding: 5px;
    max-width: 70%;
    border-radius: 30px;
    margin: auto;
    margin-top: 20px;
}

.ContactCard a {
    color: white !important;
    font-size: 1.3rem !important;
    text-decoration: none;
}

@media (max-width: 50em) {
    .ContactCard {
        max-width: 100%;
        font-size: 1rem;
    }
}