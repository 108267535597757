.SkillContainer {
    
}

.Skill {
    display: inline-block;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.2);
    padding: 10px 12px;
    margin-right:10px;
    margin-bottom: 10px;
    color: #fff;
    border-radius: 15px;
    background-color: rgb(27, 102, 136);
    overflow-y: hidden;
}